import { mapParticipant } from '@/shared/services/participant.service';
import { HttpClient } from '@angular/common/http';
import { Injectable, computed } from '@angular/core';
import { TrainingCatalogApiSettings } from '@euricom/angular-training-catalog-api';
import {
  BookingStatus as ApiBookingStatus,
  BookingDetailDto,
  BookingDto,
  CommentDto,
} from '@euricom/angular-training-catalog-api/models';
import { BookingsService as RootBookingService } from '@euricom/angular-training-catalog-api/services';
import { firstValueFrom } from 'rxjs';
import { BookingStatus } from '../models/BookingStatus';
import { Booking } from '../models/booking';
import { BookingDetail } from '../models/bookingDetail';
import { Comment } from '../models/comment';
import { User } from '../models/user';
import { MaybeSignal, unsignal } from '../utils/signals';
import { mapPeriod, mapTraining } from './training.service';

function mapBooking(dto: BookingDto): Booking {
  return {
    ...dto,
    requesterName: dto.requesterName ?? '',
    requesterEmail: dto.requesterEmail ?? '',
    createdOn: new Date(dto.createdOn),
    lastModifiedOn: new Date(dto.lastModifiedOn),
    startDate: dto.startDate ? new Date(dto.startDate) : null,
    endDate: dto.endDate ? new Date(dto.endDate) : null,
    training: mapTraining(dto.training),
    dates: dto.dates.map((period) => mapPeriod(period)),
  };
}

function mapBookingDetail(dto: BookingDetailDto): BookingDetail {
  return {
    ...dto,
    createdOn: new Date(dto.createdOn),
    lastModifiedOn: new Date(dto.lastModifiedOn),
    startDate: dto.startDate ? new Date(dto.startDate) : null,
    endDate: dto.endDate ? new Date(dto.endDate) : null,
    comments: dto.comments.map((comment) => mapComment(comment)),
    training: mapTraining(dto.training),
    participants: dto.participants?.map((participant) => mapParticipant(participant)) ?? [],
  };
}

// Create a mapping function
function mapBookingStatus(status: BookingStatus): ApiBookingStatus {
  switch (status) {
    case 'Added':
      return ApiBookingStatus.Added;
    case 'Requested':
      return ApiBookingStatus.Requested;
    case 'Approved':
      return ApiBookingStatus.Approved;
    case 'PendingBooking':
      return ApiBookingStatus.PendingBooking;
    case 'Booked':
      return ApiBookingStatus.Booked;
    case 'Verified':
      return ApiBookingStatus.Verified;
    case 'Rejected':
      return ApiBookingStatus.Rejected;
    case 'Cancelled':
      return ApiBookingStatus.Cancelled;
    default:
      // Optional: throw an error or handle unexpected status
      throw new Error(`Unexpected booking status: ${status}`);
  }
}

export function mapComment(dto: CommentDto): Comment {
  return {
    ...dto,
    date: new Date(dto.date),
  };
}

export type AddAndRequestBookingVariables = {
  trainingId: string;
  selectedOptions: Array<string>;
  motivation: string;
  addForUser?: User;
};
export type AddBookingVariables = {
  selectedOptions?: null | Array<string>;
  addForUser?: User;
  trainingId: string;
};

export type UpdateBookingVariables = { bookingId: string; selectedOptions: string[] };
export type RequestBookingVariables = { bookingId: string; selectedOptions: string[] | null; motivation: string };
export type HandleBookingVariables = { bookingId: string; selectedOptions?: string[] | null; comment: string };
export type HandleStartPendingVariables = { bookingId: string; selectedOptions?: string[] | null; comment?: string };
export type handleBookingBulkVariables = {
  refund?: boolean;
  body: {
    bookingIds?: null | string[];
    comment?: null | string;
  };
};
export type DeleteBookingVariables = { bookingId: string };
export type DeleteBookingsVariables = { bookingIds: string[] };
export type CancelBookingVariables = {
  bookingId: string;
  body?: { comment: string | null; refundCredits: boolean | null };
};

export type ChangeBookingStatusVariables = {
  bookingId: string;
  status: BookingStatus;
  comment?: string;
};

export const bookingKeys = {
  // all bookings
  all: ['bookings'] as const,

  //specific booking
  byId: (id: string | null) => [...bookingKeys.all, id] as const,

  // all the lists
  lists: () => [...bookingKeys.all, 'list'] as const,

  // booking list by season
  listBySeasons: (seasons: MaybeSignal<string[]>) =>
    computed(() => [...bookingKeys.lists(), unsignal(seasons)] as const),

  // my booking list
  listMy: () => [...bookingKeys.lists(), 'my'] as const,

  // my booking list calendar
  listMyCalendar: () => [...bookingKeys.lists(), 'my-calendar'] as const,
};

export const getRequesters = (bookings: MaybeSignal<Booking[] | undefined>) => {
  const bookingData = unsignal(bookings) ?? [];
  return bookingData
    .reduce((acc, item) => {
      if (!acc.some((accItem) => accItem.id === item.requesterEmail)) {
        acc.push({ id: item.requesterEmail, name: item.requesterName });
      }
      return acc;
    }, [] as { id: string; name: string }[])
    .map((item) => {
      return {
        key: item.id,
        displayValue: item.name,
      };
    })
    .sort((a, b) => a.displayValue.localeCompare(b.displayValue));
};

@Injectable()
export class BookingsService extends RootBookingService {
  constructor(config: TrainingCatalogApiSettings, http: HttpClient) {
    super({ rootUrl: config.baseUrl }, http);
  }

  getBookingsAsync = async (params?: { myBookings?: boolean; seasons?: Array<string> }): Promise<Booking[]> => {
    const data = await firstValueFrom(this.getBookings(params));
    return data.map(mapBooking);
  };
  getBookingAsync = async (params: { id: string }): Promise<BookingDetail> => {
    const data = await firstValueFrom(this.getBooking(params));
    return mapBookingDetail(data);
  };

  addAndRequestBookingAsync = async ({
    trainingId,
    selectedOptions,
    motivation,
    addForUser,
  }: AddAndRequestBookingVariables): Promise<BookingDetail> => {
    const booking = await firstValueFrom(this.addBooking({ body: { trainingId, selectedOptions, addForUser } }));
    const data = await firstValueFrom(this.requestBooking({ id: booking.id, body: { motivation } }));
    return mapBookingDetail(data);
  };

  addBookingAsync = async (variables: AddBookingVariables): Promise<BookingDetail> => {
    const data = await firstValueFrom(this.addBooking({ body: variables }));
    return mapBookingDetail(data);
  };

  updateBookingAsync = async ({ bookingId, selectedOptions }: UpdateBookingVariables): Promise<BookingDetail> => {
    const data = await firstValueFrom(
      this.updateBooking({ id: bookingId, body: { selectedOptions: selectedOptions } }),
    );
    return mapBookingDetail(data);
  };
  requestBookingAsync = async ({
    bookingId,
    selectedOptions,
    motivation,
  }: RequestBookingVariables): Promise<BookingDetail> => {
    if (selectedOptions)
      await firstValueFrom(this.updateBooking({ id: bookingId, body: { selectedOptions: selectedOptions } }));
    const data = await firstValueFrom(this.requestBooking({ id: bookingId, body: { motivation } }));
    return mapBookingDetail(data);
  };

  startPendingBookingAsync = async ({
    bookingId,
    selectedOptions,
    comment,
  }: HandleStartPendingVariables): Promise<BookingDetail> => {
    if (selectedOptions)
      await firstValueFrom(this.updateBooking({ id: bookingId, body: { selectedOptions: selectedOptions } }));
    const data = await firstValueFrom(this.startPendingBooking({ id: bookingId, comment: comment }));
    return mapBookingDetail(data);
  };

  startPendingBookingBulkAsync = async ({
    body,
  }: handleBookingBulkVariables): Promise<{ changedIds: string[]; unchangedIds: string[] }> => {
    return firstValueFrom(this.startPendingBookingBulk({ body: body }));
  };

  updatePendingBookingAsync = async ({
    bookingId,
    comment,
    selectedOptions,
  }: HandleBookingVariables): Promise<BookingDetail> => {
    if (selectedOptions)
      await firstValueFrom(this.updateBooking({ id: bookingId, body: { selectedOptions: selectedOptions } }));
    const data = await firstValueFrom(this.updatePendingBooking({ id: bookingId, comment: comment }));
    return mapBookingDetail(data);
  };

  updatePendingBookingBulkAsync = async ({
    bookingIds,
    comment,
  }: {
    bookingIds: string[];
    comment: string;
  }): Promise<{ changedIds: string[]; unchangedIds: string[] }> => {
    return firstValueFrom(this.updatePendingBookingBulk({ comment: comment, body: bookingIds }));
  };

  bookBookingAsync = async ({
    bookingId,
    comment,
    selectedOptions,
  }: HandleBookingVariables): Promise<BookingDetail> => {
    if (selectedOptions)
      await firstValueFrom(this.updateBooking({ id: bookingId, body: { selectedOptions: selectedOptions } }));
    const data = await firstValueFrom(this.bookBooking({ id: bookingId, comment }));
    return mapBookingDetail(data);
  };

  bookBookingBulkAsync = async ({
    body,
  }: handleBookingBulkVariables): Promise<{ changedIds: string[]; unchangedIds: string[] }> => {
    return firstValueFrom(this.bookBookingBulk({ body: body }));
  };

  approveBookingAsync = async ({
    bookingId,
    selectedOptions,
    comment,
  }: HandleBookingVariables): Promise<BookingDetail> => {
    if (selectedOptions)
      await firstValueFrom(this.updateBooking({ id: bookingId, body: { selectedOptions: selectedOptions } }));
    const data = await firstValueFrom(this.approveBooking({ id: bookingId, comment }));
    return mapBookingDetail(data);
  };

  rejectBookingAsync = async ({ bookingId, comment }: HandleBookingVariables): Promise<BookingDetail> => {
    const data = await firstValueFrom(this.rejectBooking({ id: bookingId, comment }));
    return mapBookingDetail(data);
  };

  deleteBookingAsync = async ({ bookingId }: DeleteBookingVariables): Promise<string> => {
    await firstValueFrom(this.deleteBooking({ id: bookingId }));
    return bookingId;
  };

  deleteBookingBulkAsync = async ({
    bookingIds,
  }: DeleteBookingsVariables): Promise<{ changedIds: string[]; unchangedIds: string[] }> => {
    return firstValueFrom(this.deleteBookingBulk({ body: bookingIds }));
  };

  verifyBookingAsync = async (bookingId: string): Promise<BookingDetail> => {
    const data = await firstValueFrom(this.verifyBooking({ id: bookingId }));
    return mapBookingDetail(data);
  };

  verifyBookingBulkAsync = async ({
    body,
  }: handleBookingBulkVariables): Promise<{ changedIds: string[]; unchangedIds: string[] }> => {
    return firstValueFrom(this.verifyBookingBulk({ body: body }));
  };

  cancelBookingAsync = async ({ bookingId, body }: CancelBookingVariables): Promise<BookingDetail> => {
    const data = await firstValueFrom(this.cancelBooking({ id: bookingId, body: body }));
    return mapBookingDetail(data);
  };

  changeBookingStatusAsync = async ({
    bookingId,
    status,
    comment,
  }: ChangeBookingStatusVariables): Promise<BookingDetail> => {
    const data = await firstValueFrom(
      this.changeBookingStatus({
        id: bookingId,
        body: {
          bookingStatus: mapBookingStatus(status),
          comment,
        },
      }),
    );
    return mapBookingDetail(data);
  };

  cancelBookingBulkAsync = async ({
    body,
    refund,
  }: handleBookingBulkVariables): Promise<{ changedIds: string[]; unchangedIds: string[] }> => {
    return firstValueFrom(this.cancelBookingBulk({ refundCredits: refund, body: body }));
  };

  exportBookingsOfSeasonsAsync = async (seasons: string[]): Promise<Blob> => {
    return firstValueFrom(this.exportBookingsOfSeasons({ seasonIdsIn: seasons }));
  };

  getIcalOfBookingAsync = async (id: string): Promise<Blob> => {
    return firstValueFrom(this.getIcalOfBooking({ id: id }));
  };
}
