import { Booking } from '@/shared/models/booking';
import { Holiday } from '@/shared/models/holiday';
import { Season } from '@/shared/models/season';
import { Training } from '@/shared/models/training';
import { MaybeSignal, unsignal } from '@/shared/utils/signals';

export const filterAndSortTrainings = (
  trainings: MaybeSignal<Training[] | undefined>,
  season: MaybeSignal<string>,
  domains: MaybeSignal<string[] | null>,
  formats: MaybeSignal<string[] | null>,
  other: MaybeSignal<string[] | null>,
) => {
  return (unsignal(trainings) ?? [])
    .filter((item) => {
      const draft = unsignal(other)?.includes('3');
      if (!draft) {
        return item.isEnabled !== undefined;
      }
      return true;
    })
    .filter((item) => {
      const seasonFilter = unsignal(season);
      return item.season.id === seasonFilter;
    })
    .filter((item) => {
      const domainFilter = unsignal(domains) || [];
      return domainFilter.length === 0 || item.domains.some((domain) => domainFilter.includes(domain.id));
    })
    .filter((item) => {
      const formatFilter = unsignal(formats) || [];
      return formatFilter.length === 0 || formatFilter.includes(item.format);
    });
};

export const filterBookings = (
  bookings: MaybeSignal<Booking[] | undefined>,
  season: MaybeSignal<string>,
  other: MaybeSignal<string[] | null>,
) => {
  return (unsignal(bookings) ?? []).filter((item) => {
    const seasonFilter = unsignal(season);
    const otherFilters = unsignal(other);

    if (otherFilters?.includes('0')) {
      return item.training.season.id === seasonFilter;
    }
    return false;
  });
};

export const filterHolidays = (
  holidays: MaybeSignal<Holiday[] | undefined>,
  other: MaybeSignal<string[] | null>,
  filter: string,
) => {
  const unsignaledHolidays = unsignal(holidays) ?? [];
  const otherFilters = unsignal(other);

  if (otherFilters?.includes(filter)) {
    return unsignaledHolidays;
  }
  return [];
};

export const getSeasonFilters = (seasonsSignal: MaybeSignal<Season[] | undefined>) => {
  const seasons = unsignal(seasonsSignal);
  if (!seasons) return [];
  return (
    seasons
      ?.sort((a, b) => b.year - a.year)
      ?.map((season) => {
        return { displayValue: season.year.toString(), key: season.id };
      }) ?? []
  );
};

const isDateInRange = (startDate: Date | null | undefined, endDate: Date | null | undefined, day: Date) => {
  if (!startDate || !endDate) return false;
  return !(day < startDate || day > endDate);
};

// TODO: refactor (generics?)
export const checkIfTrainingsOnDay = (trainings: Training[], day: Date) => {
  return trainings.filter((training) => training.dates.some((p) => isDateInRange(p.start, p.end, day)));
};

export const checkIfBookingsOnDay = (bookings: Booking[], day: Date) => {
  return bookings.filter((booking) => booking.dates.some((p) => isDateInRange(p.start, p.end, day)));
};

export const checkIfHolidaysOnDay = (holidays: Holiday[], day: Date) => {
  return holidays.filter((holiday) => {
    const startDate = holiday.startDate;
    const endDate = holiday.endDate;
    return isDateInRange(startDate, endDate, day);
  });
};

export const generateColorString = (colors: string[]) => {
  let colorString = '';

  colors.forEach((color, index) => {
    const verticalOffset = -3 - index * 2; // increment the vertical offset by 2 pixels
    colorString += `${color} 0px ${verticalOffset}px 0px 0px inset`;
    if (index < colors.length - 1) {
      colorString += ', ';
    }
  });
  return colorString;
};
